<template>
  <div id="app" class="d-flex flex-column h-100">
    <main class="flex-shrink-0">
            <!-- Navigation-->
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top ">
                <div class="container px-5">
                    <a class="navbar-brand" href="index.html"><img src="./assets/Pictures/MILDATA1.png" alt="Mildata" class="mildata_brand"></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item"><a class="nav-link" href="index.html">Home</a></li>
                            <li class="nav-item"><a class="nav-link" @click="ContactDes('contac')" href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
            <!-- Header-->
            <header class="bg-dark py-5 vh-lg-100">
                <div class="container px-5">
                    <div class="row gx-5 align-items-center justify-content-center">
                        <div class="col-lg-8 col-xl-7 col-xxl-6">
                            <div class="my-5 text-center text-xl-start">
                                <h1 class="display-5 fw-bolder text-white mb-2 text-center n-wrap">expertise. always.</h1>
                                <p class="lead fw-normal text-white-50 mb-4 text-center">in mildata, we always care about perfection, timing and responsibility. we are experts.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul class="flexbx">
                            <li><img src="./assets/Pictures/MBD_W.png" alt="" class="brands"></li>
                            <li><img src="./assets/c4defence_yatay_b.png" alt="" class="brands"></li>
                            <li><img src="./assets/logo ssorg.png" alt="" class="brands"></li>
                            <li><p class="askeriData text-center">ASKERI DATA PUBLICATIONS</p></li>
                            <li><img src="./assets/DLSS logo yeni tarihli beyaz.png" alt="" class="brands dlss"></li>
                            <li><img src="./assets/francetvbeyaz.png" alt="" class="brands"></li>
                        </ul>
                    </div>
                </div>
            </header>
            <!-- Features section-->
            <section class="py-5" id="features">
                <div class="container px-5 my-5">
                    <div class="row gx-5">
                        <div class="col-lg-4 mb-5 mb-lg-0 d-flex align-items-center"><h2 class="fw-bolder">Our Services</h2></div>
                        <div class="col-lg-8">
                            <div class="row gx-5 row-cols-1 row-cols-md-2">
                                <div class="col mb-5 h-100">
                                    <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-collection"></i></div>
                                    <h2 class="h5 fw-bolder">Defence Media</h2>
                                    <p class="mb-0">Publishing most-read defence media products.</p>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img src="./assets/c4defence_yatay_sb.png" alt="C4Defence" class="secBanner">
                                    <img src="./assets/logo ssorgsiyah.png" class="secBanner" alt="SavunmaSanayiOrg">
                                    </div>
                                </div>
                                <div class="col mb-5 h-100">
                                    <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-building"></i></div>
                                    <h2 class="h5 fw-bolder">Military Big Data</h2>
                                    <p class="mb-0">The thin red line between information and intelligence.</p>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img src="./assets/Pictures/MBD_B.png" class="secBanner"  alt="Military Big Data">
                                    </div>
                                </div>
                                <div class="col mb-5 mb-md-0 h-100">
                                    <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                                    <h2 class="h5 fw-bolder">Agency</h2>
                                    <p class="mb-0">Representing world's most powerful media.</p>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img src="./assets/francetvsiyah.png" alt="Defence News" class="secBanner">
                                    </div>
                                </div>
                                <div class="col h-100">
                                    <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                                    <h2 class="h5 fw-bolder">Events</h2>
                                    <p class="mb-0">Guiding the requirements with dedicated events.</p>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <img src="./assets/DLSS logo yeni tarihli siyah.png" alt="DLSS" class="secBanner dlssX">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Testimonial section-->
            <div class="py-5 bg-light">
                <div class="container px-5 my-5">
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-10 col-xl-7">
                            <div class="text-center">
                                <div class="fs-4 mb-4 fst-italic">"those who set out not to rest never get tired"</div>
                                <div class="d-flex align-items-center justify-content-end">
                                    <p class="fs-4 mb-4 fw-bold">Mustafa Kemal Atatürk</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Blog preview section-->
            <!-- <section class="py-5">
                <div class="container px-5 my-5">
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-8 col-xl-6">
                            <div class="text-center">
                                <h2 class="fw-bolder">From our blog</h2>
                                <p class="lead fw-normal text-muted mb-5">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque fugit ratione dicta mollitia. Officiis ad.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-5">
                        <div class="col-lg-3 mb-5">
                            <div class="card h-100 shadow border-0">
                                <img class="card-img-top" src="https://dummyimage.com/600x350/ced4da/6c757d" alt="..." />
                                <div class="card-body p-4">
                                    <div class="badge bg-primary bg-gradient rounded-pill mb-2">Defence Media</div>
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">Blog post title</h5></a>
                                    <p class="card-text mb-0">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                </div>
                                <div class="card-footer p-4 pt-0 bg-transparent border-top-0">
                                    <div class="d-flex align-items-end justify-content-between">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-5">
                            <div class="card h-100 shadow border-0">
                                <img class="card-img-top" src="https://dummyimage.com/600x350/ced4da/6c757d" alt="..." />
                                <div class="card-body p-4">
                                    <div class="badge bg-primary bg-gradient rounded-pill mb-2">Military Big Data</div>
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">Blog post title</h5></a>
                                    <p class="card-text mb-0">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                </div>
                                <div class="card-footer p-4 pt-0 bg-transparent border-top-0">
                                    <div class="d-flex align-items-end justify-content-between">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-5">
                            <div class="card h-100 shadow border-0">
                                <img class="card-img-top" src="https://dummyimage.com/600x350/adb5bd/495057" alt="..." />
                                <div class="card-body p-4">
                                    <div class="badge bg-primary bg-gradient rounded-pill mb-2">Agency</div>
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">Another blog post title</h5></a>
                                    <p class="card-text mb-0">This text is a bit longer to illustrate the adaptive height of each card. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                </div>
                                <div class="card-footer p-4 pt-0 bg-transparent border-top-0">
                                    <div class="d-flex align-items-end justify-content-between">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-5">
                            <div class="card h-100 shadow border-0">
                                <img class="card-img-top" src="https://dummyimage.com/600x350/6c757d/343a40" alt="..." />
                                <div class="card-body p-4">
                                    <div class="badge bg-primary bg-gradient rounded-pill mb-2">Events</div>
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">The last blog post title is a little bit longer than the others</h5></a>
                                    <p class="card-text mb-0">Some more quick example text to build on the card title and make up the bulk of the card's content.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->
            <div ref="contac">
                <contact-data></contact-data>
            <footer-data></footer-data>
            </div>
        </main>
        
  </div>
</template>

<script>
import contactData from "./components/Contact.vue"
import footerData from "./components/Footer.vue"
export default {
  name: 'App',
  components: {
    contactData,
    footerData
  },
  methods: {
      ContactDes(refName) {
          var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
      }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.navbar-toggler{
    border: none !important;
    box-shadow: none !important;
}
.flexbx{
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20em;
}
.mildata_brand{
  width: 5em;
}

.footer_banner{
    width: 6em;
}
.secBanner{
    height: 100%;
    width: 5em;
    margin-top: 2em;
    margin-inline: 2em;
}
.navbar-dark{
    color: white !important;
}
.dlssX{
    width: 3em;
}
.brands{
    width: 9em;
    margin-inline: 4em;
}
.dlss{
    width: 5em;
    
}
.askeriData{
    color: white;
    font-size: 1.05em;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}
</style>
