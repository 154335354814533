<template>
    <footer class="bg-dark py-4 mt-auto">
      <div class="container px-5">
        <div
          class="row align-items-center justify-content-between flex-column flex-sm-row"
        >
          <div class="col-auto">
            <div class="small m-0 text-white">
              <img
                src="../assets/Pictures/MILDATA_W1.png"
                alt="Mildata"
                class="footer_banner"
              />
              Copyright &copy; Mildata {{Year()}}
            </div>
          </div>
          <div class="col-auto">
            <a class="link-light small" href="#!">Contact</a>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            time: new Date,
        }
    },
    methods: {
        Year() {
            let year = this.time.getFullYear().toString();
            return year;
        },
        
    },
};
</script>
